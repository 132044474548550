import HijrahDate from 'hijrah-date';
import 'hijri-date';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';

export const subtractYears = (numOfYears = 18, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears);
  const maxDate = date.toHijri().subtractDay();

  return maxDate.format('yyyy-mm-dd');
};

export const getGregorianDate = (hijriDate) => {
  if (!hijriDate) {
    return '';
  }
  const [year, month, day] = hijriDate.split('-');

  const date = new HijriDate(+year, +month, +day + 1).toGregorian();

  const res = date.getFullYear() + '-' + (+date.getMonth() + 1) + '-' + date.getDate();
  console.log(res);
  return res;
};

export const toHijriFormat = (date) => {
  const gregDate = new Date(date);
  return toHijri(gregDate).format('yyyy/mm/dd');
};

export const convertToHijhri = (date) => {
  return `${new HijrahDate(date).format('longDate', 'en')} AH`;
};

export const formatHIjhri = (date) => {
  return new HijriDate(date).format('dd/mmmm/yyyy');
};

export const getHijhri = (date) => {
  return new HijrahDate(date).format('yyyy-MM-dd');
};
